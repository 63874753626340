import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Header from "./Header"
import ProductsProvider from "./ProductsProvider"
import CartProvider from "./CartProvider"
import { main, footer, grid, copyright } from "./StoreLayout.module.css"

const StoreLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      site {
        siteMetadata {
          title
          email
          phone
          address
          facebook
        }
      }
    }
  `)

  return (
    <ProductsProvider>
      <CartProvider>
        <Header cart></Header>
        
        <main className={main}>{children}</main>

        <footer className={footer}>
          <div className="container section">
            <div className={grid}>
              <div>
                <h3>Resources</h3>
                <ul>
                  <li><AnchorLink to="/#order">Services We Provide</AnchorLink></li>
                  <li><AnchorLink to="/service/amazon-reactivate-account">Reactivate Amazon Deactivated Account</AnchorLink></li>
                  <li><Link to="/pages/refund-policy">Refund Policy</Link></li>
                  <li><AnchorLink to="/#faq">FAQs</AnchorLink></li>
                </ul>
              </div>
              <div id="contact">
                <h3>Contact Us</h3>
                <ul>
                  <li>Email: {data.site.siteMetadata.email}</li>
                  <li>Phone: {data.site.siteMetadata.phone}</li>
                  <li>Address: {data.site.siteMetadata.address}</li>
                  <li>
                    <a href={data.site.siteMetadata.facebook}>
                      <img height="32" width="32" src="https://unpkg.com/simple-icons@3.11.0/icons/facebook.svg"/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className={copyright}>
            <div><small>©  {new Date().getFullYear()} {data.site.siteMetadata.title}</small></div>
            <small><Link to="/pages/terms">Terms</Link></small>
            <small><Link to="/pages/privacy">Privacy</Link></small>
          </div>
        </footer>
      </CartProvider>
    </ProductsProvider>
  )
}

StoreLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StoreLayout